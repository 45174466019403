import React from "react"
import PropTypes from "prop-types"

import Image from "@nerdwallet/react-image"

import styles from "./Illustration.module.less"

const Illustration = ({ alt, src, width, height, ...rest }) => (
  <Image
    alt={alt}
    backgroundColor="transparent"
    className={styles.illustration}
    src={src}
    naturalHeight={height}
    naturalWidth={width}
    height={height}
    width={width}
    lazy
    {...rest}
  />
)

Illustration.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
}

Illustration.defaultProps = {
  width: null,
  height: null,
}

export default Illustration
