import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import Box from "@src/components/Box"

import styles from "./CtaCard.module.less"

const CtaCard = ({
  children,
  sticky,
  boundedWidth,
  mobileOnly,
  centered,
  verticalMargin,
}) => {
  return (
    <Box
      className={classNames(styles.box, {
        [styles.sticky]: sticky,
        [styles.boundedWidth]: boundedWidth,
        [styles.mobileOnly]: mobileOnly,
        [styles.centered]: centered,
        [styles.verticalMargin]: verticalMargin,
      })}
    >
      {children}
    </Box>
  )
}

CtaCard.propTypes = {
  children: PropTypes.node.isRequired,
  sticky: PropTypes.bool,
  boundedWidth: PropTypes.bool,
  mobileOnly: PropTypes.bool,
  centered: PropTypes.bool,
  verticalMargin: PropTypes.bool,
}

CtaCard.defaultProps = {
  onSelectionChange: null,
  sticky: false,
  boundedWidth: false,
  mobileOnly: false,
  centered: false,
  verticalMargin: false,
}

export default CtaCard
