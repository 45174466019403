import React from "react"
import PropTypes from "prop-types"

import Page, { Footer, Header, HeaderContent } from "@src/components/Page"
import Column from "@src/components/Columns/Column"
import Columns from "@src/components/Columns/Columns"

import LoremIpsum from "@src/components/LoremIpsum"

import { ApplyUrlProvider } from "@src/hooks/useApplyUrl"
import MediumProductCard from "@src/components/StructuredContentRenderer/MediumProductCard"
import {
  creditCard,
  bankingProduct,
  bankingProductMonthlyFeeOnly,
} from "@src/components/StructuredContentRenderer/MediumProductCard/__fixtures__/MediumProductCard.fixtures"

const MediumProductCardPage = ({ location }) => {
  const title = "Medium Product Card Page"
  const breadcrumb = {
    page: {
      title,
    },
    slug: "SMALL_BUSINESS",
  }
  return (
    <ApplyUrlProvider
      location={location}
      attributionTopic="Business Credit Cards"
    >
      <Page
        title={title}
        header={<Header />}
        headerContent={<HeaderContent title={title} breadcrumb={breadcrumb} />}
        footer={<Footer />}
      >
        <Columns>
          <Column width={{ desktop: 8, mobile: 12 }}>
            <LoremIpsum />
            <LoremIpsum />
            <MediumProductCard {...creditCard} />
            <LoremIpsum />
            <MediumProductCard {...bankingProduct} />
            <LoremIpsum />
            <MediumProductCard {...bankingProductMonthlyFeeOnly} />
            <LoremIpsum />
            <LoremIpsum />
          </Column>
          <Column width={{ desktop: 4, mobile: 12 }}>[right rail]</Column>
        </Columns>
      </Page>
    </ApplyUrlProvider>
  )
}

MediumProductCardPage.propTypes = {
  location: PropTypes.shape({}).isRequired,
}

export default MediumProductCardPage
