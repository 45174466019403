// https://github.com/NerdWallet/currency/blob/987728a48a8062c2e48f798e323a15c3f6383865/src/components/StarRating/Stars.jsx
import React from "react"

import classNames from "classnames"

import styles from "./Stars.module.less"

const Star = ({ className, error, nerdwallet, small, ...otherProps }) => {
  const firstHalfStarClasses = classNames(styles["star--first-half"], {
    [styles.error]: error,
    [styles.green]: nerdwallet,
    [styles.yellow]: !nerdwallet,
  })

  const secondHalfStarClasses = classNames(styles["star--second-half"], {
    [styles.error]: error,
    [styles.green]: nerdwallet,
    [styles.yellow]: !nerdwallet,
  })

  return (
    <svg
      viewBox="0 0 14 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(className, styles.star, {
        [styles.small]: small,
      })}
      {...otherProps}
    >
      <defs>
        <filter id="outline_selected">
          <feFlood floodColor="#00aacc" result="base" />
          <feMorphology
            result="bigger"
            in="SourceGraphic"
            operator="dilate"
            radius="1"
          />
          <feColorMatrix
            result="mask"
            in="bigger"
            type="matrix"
            values="0 0 0 0 0
                    0 0 0 0 0
                    0 0 0 0 0
                    0 0 0 1 0"
          />
          <feComposite result="drop" in="base" in2="mask" operator="in" />
          <feGaussianBlur result="blur" in="drop" stdDeviation="1" />
          <feBlend in="SourceGraphic" in2="blur" mode="normal" />
        </filter>
      </defs>
      <g
        stroke="none"
        strokeWidth="1"
        fillRule="evenodd"
        className={styles["inner-group"]}
      >
        <polygon
          className={firstHalfStarClasses}
          transform="translate(3.501500, 6.650000) translate(-3.501500, -6.650000) "
          points="6.996 0 5.03 4.63725674 0 5.0730929 3.813 8.38284867 2.674 13.3000002 7.001 10.6979784 7.003 10.6999776"
        />
        <polygon
          className={secondHalfStarClasses}
          transform="translate(10.470000, 6.650000) scale(-1, 1) translate(-10.490000, -6.650000)"
          points="13.9830028 -1.0658141e-14 12.017845 4.63725674 6.98999977 5.0730929 10.8013663 8.38284867 9.66285426 13.3000002 13.9880006 10.6979784 13.9899998 10.6999776"
        />
      </g>
    </svg>
  )
}

Star.displayName = "Star"

if (process.env.NODE_ENV !== "production") {
  // eslint-disable-next-line global-require
  const PropTypes = require("prop-types")
  Star.propTypes = {
    className: PropTypes.string,
    nerdwallet: PropTypes.bool,
    error: PropTypes.bool,
    small: PropTypes.bool,
  }
}

Star.defaultProps = {
  className: null,
  nerdwallet: false,
  error: false,
  small: false,
}

/** produces a set of stars, optionally as inputs */
const Stars = ({
  id,
  error,
  maxRating,
  nerdwallet,
  onChange,
  rating,
  small,
  ...otherProps
}) => {
  const stars = []

  const isInput = !!id

  for (let index = 0; index < maxRating; index += 1) {
    // Logic for determining if any given star should be highlighted.
    const value = index + 1
    const isInRange = value <= rating
    const checked = Math.ceil(rating) === value
    const isHalfHighlighted = checked && value > rating
    const starId = `${id}__${value}`

    let starClass = styles.empty
    if (isInRange) {
      starClass = styles.full
    } else if (isHalfHighlighted) {
      starClass = styles.half
    }

    const classes = classNames(starClass, {
      [styles["is-clickable"]]: isInput,
      [styles["is-selected"]]: isInRange,
      [styles["star-field"]]: isInput,
    })

    const radio = isInput && (
      <input
        className={styles.radio}
        type="radio"
        checked={checked}
        id={starId}
        name={id}
        onChange={onChange}
        onClick={onChange}
        value={value}
        {...otherProps}
      />
    )

    const star = isInput ? (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control -- directive added automatically by Shepherd migration
      <label htmlFor={starId} className={styles.label}>
        <Star
          role="presentation"
          aria-label={`${value} out of ${maxRating}`}
          nerdwallet={nerdwallet}
          error={error}
          small={small}
        />
      </label>
    ) : (
      <Star nerdwallet={nerdwallet} error={error} small={small} />
    )

    stars.push(
      <span
        className={classNames(classes, {
          [styles.centerVertical]: small,
        })}
        key={index}
      >
        {radio}
        {star}
      </span>
    )
  }

  if (isInput) {
    return <React.Fragment>{stars}</React.Fragment>
  }

  return <div {...otherProps}>{stars}</div>
}

Stars.displayName = "Stars"

if (process.env.NODE_ENV !== "production") {
  // eslint-disable-next-line global-require
  const PropTypes = require("prop-types")
  Stars.propTypes = {
    id: PropTypes.string,
    error: PropTypes.bool,
    maxRating: PropTypes.number,
    nerdwallet: PropTypes.bool,
    onChange: PropTypes.func,
    rating: PropTypes.number,
    small: PropTypes.bool,
  }
}

Stars.defaultProps = {
  error: false,
  id: null,
  onChange: () => {},
  maxRating: null,
  nerdwallet: false,
  rating: null,
  small: false,
}

export default Stars
