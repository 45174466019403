import React from "react"
import PropTypes from "prop-types"

import { CTA } from "@src/components/CtaCard"

import {
  buildElementPositionAttributes,
  buildProductShoppingAttributes,
} from "@src/lib/analytics_payloads"
import { withImpression } from "@src/lib/impression"

const TrackedCTA = ({
  cta,
  id,
  productType,
  product,
  institution,
  href,
  ...props
}) => (
  <CTA
    ctaType={cta.type}
    href={href || cta.link}
    association={cta.subtext}
    {...props}
  >
    {cta.text}
  </CTA>
)

TrackedCTA.propTypes = {
  id: PropTypes.string.isRequired,
  productType: PropTypes.string.isRequired,
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  institution: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  cta: PropTypes.shape({
    link: PropTypes.string.isRequired,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    subtext: PropTypes.string,
    type: PropTypes.string.isRequired,
  }),
  hideAssociation: PropTypes.bool,
  monetizable: PropTypes.string,
  ...CTA.propTypes,
}

export default withImpression(
  TrackedCTA,
  {
    eventType: "product_impression",
    payload: ({ props }) => {
      return {
        ...buildElementPositionAttributes({
          sectionName: props.ctaType || props.cta.type,
        }),
        ...buildProductShoppingAttributes({
          marketplace_product_type: props.productType,
          marketplace_institution_id: props.institution.id,
          marketplace_product_id: props.product.id,
          marketplace_offer_id: props.id,
          ...(props.monetizable && { monetizable: props.monetizable }),
        }),
        entity_name: props.ctaType || props.cta.type,
        destination_url: props.href || props.cta.link,
      }
    },
  },
  {
    propWithImpression: "href",
  }
)
