import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import Button from "@src/components/Button"
import { Text } from "@nerdwallet/react-typography"

import styles from "./CTA.module.less"

const CTA = ({
  ctaType,
  children,
  href,
  association,
  hideAssociation,
  buttonClassName,
  ...rest
}) => (
  <>
    <Button
      {...rest}
      className={classNames(styles.button, buttonClassName)}
      data-cta-type={ctaType}
      data-mclick={true}
      href={href}
      primary
      rel="sponsored"
      target="_blank"
    >
      {children}
    </Button>
    {association && !hideAssociation && (
      <Text small color="neutral-darker" className={styles.text}>
        {association}
      </Text>
    )}
  </>
)

CTA.propTypes = {
  children: PropTypes.node.isRequired,
  ctaType: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  association: PropTypes.string,
  buttonClassName: PropTypes.string,
  hideAssociation: PropTypes.bool,
}

CTA.defaultProps = {
  association: "",
  buttonClassName: "",
  hideAssociation: false,
}

export default CTA
