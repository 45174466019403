import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { Title } from "@nerdwallet/react-typography"

import styles from "./Header.module.less"

const Header = ({ children, alignLeft, bottomMargin }) => (
  <Title
    size="small"
    className={classNames(styles.text, {
      [styles.alignLeft]: alignLeft,
      [styles.bottomMargin]: bottomMargin,
    })}
  >
    {children}
  </Title>
)

Header.propTypes = {
  alignLeft: PropTypes.bool,
  bottomMargin: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

Header.defaultProps = {
  alignLeft: false,
  bottomMargin: true,
}

export default Header
