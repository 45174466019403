import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import {
  usePopoverState,
  Popover,
  PopoverDisclosure,
  usePopoverArrow,
} from "reakit/Popover"

import { Text } from "@nerdwallet/react-typography"
import Help from "@nerdwallet/react-icon/nerdwallet-ui/Help"

import styles from "./Tooltip.module.less"

// Based on currency component: https://github.com/NerdWallet/currency/blob/220f9c07435ab7c447d8df9830e4b30150e2cf44/src/components/Tooltip/Tooltip.tsx#L69
const Tooltip = ({
  content,
  hiddenOnMobile,
  offsetArrow,
  "aria-label": ariaLabel,
  title,
}) => {
  const isTextTooltip = typeof content === "string"

  const innerContent = isTextTooltip ? (
    <span className={styles.tooltipContent}>{content}</span>
  ) : (
    content
  )

  const state = usePopoverState({
    placement: "bottom",
  })
  const arrowState = usePopoverArrow(state)
  const { children, ...arrowProps } = arrowState

  return (
    <>
      <PopoverDisclosure
        {...state}
        as="button"
        type="button"
        aria-label={ariaLabel ? `${ariaLabel} tooltip` : "tooltip"}
        className={classNames(styles.trigger, {
          [styles.hiddenOnMobile]: hiddenOnMobile,
        })}
        title={title || ariaLabel}
      >
        <span className={styles.tappable} aria-hidden="true" />
        <Help className={styles.icon} />
      </PopoverDisclosure>
      <Popover
        {...state}
        // use a span instead of a div to prevent `cannot nest <div> inside of <p>` DOM validation errors
        as="span"
        className={classNames(styles.tooltip, {
          [styles.textTooltip]: isTextTooltip,
        })}
        // tabIndex=0 transfers focus from trigger to popover dialog
        // when it is opened. this allows further tab-navigating
        // into the content of the tooltip (links, buttons, etc...)
        tabIndex={0}
        aria-label={ariaLabel || title}
      >
        <span
          {...arrowProps}
          className={classNames(styles.arrow, {
            [styles.arrowBottom]: state.placement === "bottom",
            [styles.arrowTop]: state.placement === "top",
            [styles.arrowOffset]: offsetArrow,
          })}
          // don't count this in element count of dialog when screen reader is reading
          aria-hidden
        />
        {isTextTooltip ? (
          <Text size="default">{innerContent}</Text>
        ) : (
          innerContent
        )}
      </Popover>
    </>
  )
}

Tooltip.propTypes = {
  "aria-label": PropTypes.string,
  hiddenOnMobile: PropTypes.bool,
  content: PropTypes.node.isRequired,
  offsetArrow: PropTypes.bool,
  title: PropTypes.string,
}

Tooltip.defaultProps = {
  "aria-label": "",
  hiddenOnMobile: false,
  offsetArrow: false,
  title: "",
}

export default Tooltip
