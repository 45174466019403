import React, { useState } from "react"
import PropTypes from "prop-types"

import Context from "./Context"

import styles from "./SelectionList.module.less"

const SelectionList = ({ children, defaultSelection, onSelectionChange }) => {
  const [selected, setSelected] = useState(defaultSelection)
  const handleSelectionChange = id => {
    if (typeof onSelectionChange === "function") {
      onSelectionChange(id)
    }
    setSelected(id)
  }

  return (
    <Context.Provider value={{ selected, setSelected: handleSelectionChange }}>
      <div className={styles.selections}>{children}</div>
    </Context.Provider>
  )
}

SelectionList.propTypes = {
  children: PropTypes.node.isRequired,
  defaultSelection: PropTypes.string.isRequired,
  onSelectionChange: PropTypes.func,
}

SelectionList.defaultProps = {
  onSelectionChange: null,
}

export default SelectionList
