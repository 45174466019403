export const creditCard = {
  id: "0952eb6d-d5be-40ee-b3d4-22a87da23813",
  align: "right",
  useLargeWidth: false,
  spacing: {
    bottom: 3,
  },
  children: null,
  marketplaceEntity: {
    __typename: "CreditCardsOfferV2",
    id: "0952eb6d-d5be-40ee-b3d4-22a87da23813",
    status: "ACTIVE",
    name: "The Blue Business® Plus Credit Card from American Express",
    slug: "American-Express-Blue-for-Business",
    image: {
      __typename: "EntityPlacementImage",
      source:
        "https://www.nerdwallet.com/cdn-cgi/image/width=1800,quality=85/cdn/images/marketplace/credit_cards/0952eb6d-d5be-40ee-b3d4-22a87da23813/028d9f1fd040233f277958b5497f32f33fe08b0bd1eeccb1cd12f5216a49f7e6.jpg",
      alt: "American Express The Blue for Business® Credit Card",
      height: 304,
      width: 480,
    },
    starRating: 4.8,
    annualFee: 0,
    reviewLink:
      "https://www.nerdwallet.com/reviews/credit-cards/amex-blue-business-plus",
    cta: {
      __typename: "EntityPlacementCTA",
      text: "Apply now",
      subtext: "on American Express' website",
      link: "https://www.nerdwallet.com/redirect/0952eb6d-d5be-40ee-b3d4-22a87da23813?name=American-Express-Blue-for-Business&clickHeader_category=Credit+Card&clickHeader_productId=1112&mvt=MTY2MDA4MzI4MDM2Mzc3NjQ5MTo2NDM0YTFkNS0zODM0LTRmZDAtYTM4ZC00ZjMyNDczNGJlMTI6YmEyMjJmNzIxMTdhNzAyNDk1OTc0YTQ0M2Q4NjM5ZWEwMDc0ZDQ0ZGYyNWJhYTgyNmU4NGU3Y2UwYzkzYTQ4YQ%3D%3D",
      type: "APPLY_NOW",
    },
    product: {
      __typename: "CreditCardsProductV2",
      id: "8d41ca3e-d913-47ca-a1ce-eea97def58d9",
      productType: "credit_cards",
      institution: {
        __typename: "CreditCardsInstitutionV2",
        id: "cd471c3b-eca7-487c-9a41-ed802b938fca",
        name: "American Express",
      },
    },
  },
}

export const bankingProduct = {
  id: "0473381a-9684-11ec-9ee4-efb084c71f45",
  useLargeWidth: false,
  children: null,
  marketplaceEntity: {
    __typename: "BankingOfferV2",
    id: "0473381a-9684-11ec-9ee4-efb084c71f45",
    status: "ACTIVE",
    name: "American Express® Business Checking",
    image: {
      __typename: "EntityPlacementImage",
      source: "https://cdn.nerdwallet.com/banking/logos/AmexNatBank.png",
      alt: "American Express® Business Checking",
    },
    starRating: 5,
    reviewLink:
      "https://www.nerdwallet.com/reviews/small-business/american-express-business-checking",
    displayApy: "1.10%",
    feeMonthly: null,
    cta: {
      __typename: "EntityPlacementCTA",
      text: "Read review",
      subtext: null,
      link: "https://www.nerdwallet.com/reviews/small-business/american-express-business-checking",
      type: "READ_REVIEW",
    },
    federallyInsuredAnnotation: "Member FDIC",
    product: {
      __typename: "BankingProductV2",
      id: "633e6f50-9683-11ec-95d7-dba18ccfac1d",
      productType: "banking",
      institution: {
        __typename: "BankingInstitutionV2",
        displayName: "American Express National Bank",
        starRating: null,
      },
    },
  },
}

export const bankingProductMonthlyFeeOnly = {
  id: "084f2d1c-4819-11e8-9c02-2b59a8343fd0",
  useLargeWidth: false,
  children: null,
  marketplaceEntity: {
    __typename: "BankingOfferV2",
    id: "084f2d1c-4819-11e8-9c02-2b59a8343fd0",
    status: "ACTIVE",
    name: "Basic Business Checking",
    image: {
      __typename: "EntityPlacementImage",
      source:
        "https://www.nerdwallet.com/cdn-cgi/image/quality=85/cdn/banking/logos/Axos_Bank.png",
      alt: "Axos Bank® Basic Business Checking",
    },
    starRating: 5,
    reviewLink:
      "https://www.nerdwallet.com/article/small-business/axos-business-checking",
    displayApy: null,
    feeMonthly: 0,
    cta: {
      __typename: "EntityPlacementCTA",
      text: "Learn more",
      subtext: "at Axos Bank®",
      link: "https://www.nerdwallet.com/redirect/084f2d1c-4819-11e8-9c02-2b59a8343fd0?mvt=MTY2MTgwNjk4NDAyMzIwODI1MDo5ZmVjY2U2MDkyMTJlNWQyYjQyYjA0Zjc5NTM5NWVlYjRlMWJlOWM4MTYzNzQ2ZGYtOWNjNS00MTVmLWMxZmEtNGJjM2VhMmFjMjY3OmIxNzExYmU5ODQzNTFhYjc1MGEyN2UyMmZjNzFkMjlkNmJlMzQzYjM2MDlhYzQ2MmNhMmQ0MDNmYmQ3NzU3NDk%3D",
      type: "APPLY_NOW",
    },
    federallyInsuredAnnotation: "Member FDIC",
    product: {
      __typename: "BankingProductV2",
      id: "1a039044-4818-11e8-9c02-9f91a7fcf9d0",
      productType: "banking",
      institution: {
        __typename: "BankingInstitutionV2",
        displayName: "Axos Bank®",
        starRating: null,
      },
    },
  },
}
